import React from "react";
import "./about.css";
import ME from "../../assets/me2.jpg";
import { FaAward } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import { VscFolderLibrary } from "react-icons/vsc";

const About = () => {
  return (
    <section id="about">
      <h5>Get to Know</h5>
      <h2>About Me</h2>
      <div className="container about__container">
        <div className="about__me">
          <img src={ME} alt="About Image" className="about__me-image" />
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>Experience</h5>
              <small>Under-Graduate</small>
            </article>

            <article className="about__card">
              <FiUsers className="about__icon" />
              <h5>Clients</h5>
              <small>4</small>
            </article>

            <article className="about__card">
              <VscFolderLibrary className="about__icon" />
              <h5>Projects</h5>
              <small>10+</small>
            </article>
          </div>
          <p className="p__hover">
            Hi, I'm Ashen Tennakoon, a creative and passionate designer with a
            knack for coding. With a year of experience in crafting visually
            appealing and user-friendly designs, I excel in both graphic and web
            design. My skill set includes Adobe Creative Suite, Figma, HTML,
            CSS, JavaScript, and React. I thrive in the entire design process,
            from initial concept to final implementation, ensuring that the end
            product is both aesthetically pleasing and functional. I love to
            push the boundaries of design and continuously learn new techniques
            and trends to stay at the forefront of the industry. Collaboration
            is key to my work style, and I enjoy working in diverse teams to
            bring ideas to life. Whether it's a branding project, a website, or
            a mobile app, I bring creativity and technical expertise to deliver
            solutions that exceed expectations. Beyond design, my coding skills
            enable me to create seamless and interactive user experiences. I'm
            always eager to take on new challenges and explore innovative ways
            to solve problems. Let's create something amazing together. I'm
            excited to collaborate and bring your vision to reality!
          </p>
          <a href="#contact" className="btn btn-primary">
            Let's Talk
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
